<template>
  <div class="info-containe">
    <h2>考场票据信息</h2>
    <div class="flex">
      <label for="" class="info-title">票据标题：</label>
      <span v-if="!edit">{{info.billNo?info.billNo:'暂无设置'}}</span>
      <a-input v-else class="flex1" v-model="info.billNo"></a-input>
    </div>
    <div class="flex">
      <label for="" class="info-title">及格分数：</label>
      <span v-if="!edit">{{info.scorePassLine}}</span>
      <a-input v-else v-model="info.scorePassLine" class="flex1" type="number" max="100" :maxLength="3"></a-input>
    </div>
    <div class="act-con">
      <a-button v-if="!edit" class="dis" @click="edit=true">编辑</a-button>
      <a-button v-if="edit" class="dis" @click="edit=false">取消</a-button>
      <a-button type="primary" v-if="edit" @click="saveInfo">保存</a-button>
    </div>
    
  </div>
</template>
<script>

export default {
  data(){
    return{
      info:{
        billNo:'',
        scorePassLine:''
      },
      edit:false
    }
  },
  mounted(){
    this.getInfo()
  },
  methods:{
    getInfo(){
      this.$post(this.$url.票据信息, {
        examSpotId:this.$store.state.curSpotId
      }).then(data => {
       this.info = data.data
       console.log('this.info',this.info)
      }).catch(() => {});
    },
    saveInfo(){
      this.$post(this.$url.设置票据信息, {
        examSpotId:this.$store.state.curSpotId,
        billNo:this.info.billNo,
        scorePassLine:this.info.scorePassLine
      }).then(data => {
        this.$tips('修改成功','票据信息编辑成功！','success')
        this.getInfo()
        this.edit = false
      }).catch(() => {});
    }
  },
  
}
</script>
<style scoped>
.info-containe{
  width:100%;
  max-width:400px;
}
.info-title,span{
  line-height:30px;
  margin-right:10px;
}
.flex{
  margin:15px 0;
}
.act-con{
  text-align:right
}
</style>